<template>
  <v-app>
    <Header
      v-if="!node.settings.no_top_menu"
      :showBarNavIcon="false"
      :showCreateBtn="false"
      :user="user"
    />
    <Content>
      <router-view :key="getKey"></router-view>
    </Content>
    <Alert />
  </v-app>
</template>

<script>
import { mapState } from "vuex";

import Content from "@/components/layouts/Content/Content.vue";
import Header from "@/components/layouts/Header/Header.vue";

export default {
  components: { Header, Content },
  computed: {
    ...mapState("core$common", ["user", "node"]),
    getKey() {
      //  rerender page components when route params will change
      const { name, params } = this.$route;
      return name + JSON.stringify(params);
    },
  },
};
</script>
